<template>
  <div style="padding: 0 10px;">
    <div class="flex_lr">
      <div class="LP_btn" style="width:48%;" @click="toNFT()">MTG交易</div>
      <div class="LP_btn" style="width:48%;" @click="show_MTG = true">MTG存量查询</div>
    </div>
    <div class="LP_box0">
      <!-- 质押池 -->
      <div class="LP_title">{{$t('nftInfo.title1')}}</div>
      <!-- MTG -->
      <div class="LP_box1 y_center">
        <div class="zy_title flex_row">
          <img src="@/image/jinshan_13.png" alt="">
          <div>MTG</div>
        </div>
        <div class="LP_num flex_row" v-if="pressureNftTokenId>0">
          <div>{{$t('nftInfo.text1')}}MTG</div>
          <div style="margin-left: 10px;">ID {{pressureNftTokenId}}</div>
        </div>
        <div class="flex_lr" style="width:100%;margin-top:10px">
          <div style="width:48%;">
            <div class="LP_btn2" :style="pressureNftTokenId>0?'width:100%;background:#eaeaea;color:#999;':''" @click="openNft">{{$t('nftInfo.text2')}}</div>

          </div>
          <div class="LP_btn2" style="width:48%;" @click="redeemNFT"  v-if="pressureNftTokenId>0">{{$t('nftInfo.text3')}}</div>
          <div class="LP_btn2"  v-else style="width:48%;background:#eaeaea;color:#999;">{{$t('nftInfo.text3')}}</div>
        </div>
      </div>
      
      <!-- MT -->
      <div class="LP_box1 y_center">
        <div class="zy_title flex_row">
          <img src="@/image/jinshan_15.png" alt="">
          <div>MT</div>
        </div>
        <div class="LP_num flex_row">
          <div>{{$t('nftInfo.text1')}}</div>
          <div style="margin-left: 10px;">{{info.relCoinMt}}</div>
        </div>
        <div class="flex_lr" style="width:100%;margin-top:10px">
          <div class="LP_btn2" style="width:48%;" @click="addPop1=1">{{$t('nftInfo.text2')}}</div>
          <div class="LP_btn2" style="width:48%;" @click="renouncePressure(1)">{{$t('nftInfo.text3')}}</div>
        </div>
      </div>
      <!-- ULM -->
      <div class="LP_box1 y_center" style="margin-top:20px;">
        <div class="zy_title flex_row">
          <img src="@/image/jinshan_10.png" alt="">
          <div>ULME</div>
        </div>
        <div class="LP_num flex_row">
          <div>{{$t('nftInfo.text1')}}</div>
          <div style="margin-left: 10px;">{{info.relCoinUlm}}</div>
        </div>
        <div class="flex_lr" style="width:100%;margin-top:10px">
          <div class="LP_btn2" style="width:48%;" @click="addPop1=2">{{$t('nftInfo.text2')}}</div>
          <div class="LP_btn2" style="width:48%;" @click="renouncePressure(2)">{{$t('nftInfo.text3')}}</div>
        </div>
      </div>
      <!-- 矿池算力 -->
      <div class="LP_title">{{$t('nftInfo.title2')}}{{name}}</div>
      <div class="LP_box1">
        <div class="LP_text flex_lr">
          <div>{{$t('nftInfo.text4').replace(/\|NAME\|/g,name)}}</div>
          <div class="LP_num">{{info.count}}{{name}}</div>
        </div>
        <!-- <div class="LP_text flex_lr" v-if="pressureNftTokenId!=undefined">
          <div>质押的{{name}}  ID</div>
          <div v-if="pressureNftTokenId>0" class="LP_num">{{pressureNftTokenId}}</div>
          <div v-else class="LP_btn hand" @click="pressureNft" style="background:#13b717;width: 31%;line-height: 0px;">质押</div>
        </div> -->
        <div class="LP_box2">
          <div class="LP_title flex_lr" style="align-items:flex-start;padding-bottom:0;">
            <div class="" style="font-size:0.8rem;">{{name}}{{$t('nftInfo.text5')}}({{nTypeName}}/ULME)</div>
            <div class="LP_num2 flex_row">
              <div>{{fromWei(relNft[0])}}MT</div>
              <div style="margin:0 3px;">/</div>
              <div>{{fromWei(relNft[1])}}ULME</div>
            </div>
          </div>
          <div class="LP_title flex_lr" style="font-size:0.8rem;">
            <div class="" style="font-size:0.8rem;">MTG{{$t('nftInfo.text6')}}(20ULME/{{$t('nftInfo.text7')}})</div>
            <div>
              <div class="LP_num2 flex_lr">{{$t('nftInfo.text9')}}<div>{{fromWei(takeUlm[0])}}<span style="color:#ff0000">ULME</span></div></div>
              <div class="LP_num2 flex_lr">{{$t('nftInfo.text8')}}<div>{{fromWei(takeUlm[1])}}<span style="color:#ff0000">ULME</span></div></div>
            </div>
          </div>
          <div class="LP_text flex_lr">
            <div class="LP_btnM hand" @click="takeNft" style="background:#13b717;padding: 8px;width: 48%;">{{$t('nftInfo.text10').replace(/\|NAME\|/g,name)}}</div>
            <div class="LP_btnM hand" @click="take" style="background:#13b717;padding: 8px;width: 48%;">{{$t('nftInfo.text11').replace(/\|NAME\|/g,name)}}</div>
          </div>
        </div>
      </div>
    </div>
      <!-- <div class="LP_title">我的{{name}}</div>
      <div class="LP_box1">
        <div class="LP_box2">
          <div class="LP_title flex_lr" style="font-size:0.8rem;">
            <div class="" style="font-size:0.8rem;">MT释放</div>
            <div>
              <div class="LP_num2 flex_lr">未领取：<div>{{fromWei(relNft[0])}}<span style="color:#ff0000">MT</span></div></div>
              <div class="LP_num2 flex_lr">已领取：<div>{{fromWei(relNft[1])}}<span style="color:#ff0000">MT</span></div></div>
            </div>
          </div>
          <div class="LP_title flex_lr" style="font-size:0.8rem;">
            <div class="" style="font-size:0.8rem;">ULM释放</div>
            <div>
              <div class="LP_num2 flex_lr">未领取：<div>{{fromWei(takeUlm[0])}}<span style="color:#ff0000">ULM</span></div></div>
              <div class="LP_num2 flex_lr">已领取：<div>{{fromWei(takeUlm[1])}}<span style="color:#ff0000">ULM</span></div></div>
            </div>
          </div>
          <div class="LP_text flex_lr">
            <div class="LP_btnM hand" @click="takeNft" style="background:#13b717;padding: 8px;width: 48%;">领取MT</div>
            <div class="LP_btnM hand" @click="take" style="background:#13b717;padding: 8px;width: 48%;">领取ULM</div>
          </div>
        </div>
      </div> 
    </div>-->
    <!-- 添加流动池 -->
    <div class="LP_box0">
      <!-- 我的等级 -->
      <div class="flex_lr">
        <div class="LP_title">{{$t('nftInfo.title3')}}</div>
        <div class="LP_title flex_row">
          <div>V{{level}}</div>
          <img :src="require('@/image/vip/V' + level+'.png')" style="width:30px;height:30px;margin-left:10px;" alt="">
        </div>
      </div>
      <!-- <div class="LP_btn" style="background: #0C2379;padding: 10px;margin: 0;color:#00FFEA;">{{nTypeName}}-USDT LP</div> -->
      <div class="LP_box1" v-if="info._relPools>0">
        <div class="LP_text flex_lr" >
          <div>{{name}}{{$t('nftInfo.text12')}}</div>
          <div class="LP_num">{{info._relPools}}ULME/{{$t('nftInfo.text7')}}</div>
        </div>
        <div class="LP_text flex_lr" >
          <div>{{name}}{{$t('nftInfo.text13')}}(MT/ULME):</div>
          <div class="LP_num">{{info.reMt}}MT</div>
          <div class="LP_num">{{info.reUlm}}ULME</div>
        </div>
      </div>
    </div>
    <!-- 质押弹窗 -->
    <!-- addPop1=1 MT -->
    <!-- addPop1=2 ULM -->
    <!-- addPop1=3 MTG -->
    <div class="pledge flex_center" v-if="addPop1>0" @touchmove.prevent @mousewheel.prevent>
      <div class="mask"></div>
      <div class="pledge_box" :style="$setWidth=='100%'?'max-width: 80%;':'max-width: 500px;'">
        <div class="pledge_img">
          <img src="@/image/jinshan_03.png" v-if="addPop1==1" alt="">
          <img src="@/image/jinshan_07.png" v-if="addPop1==2" alt="">
        </div>
        <div class="pledge_name">{{$t('nftInfo.text14')}}{{addPop1==1?'MT':addPop1==2?'ULME':''}}</div>
        <div>
          <div style="color: #00F6FF;">{{$t('nftInfo.text15')}}</div>
          <!-- MT -->
          <div v-if="addPop1==1">
            <!-- <div class="pledge_input flex_lr" style="align-items:flex-end;">
              <div>
                <div>请输入质押数量</div>
                <input type="text" :placeholder="'MT余额：'+blance_mt+'MT'" v-model="addLp1">
              </div>
              <div style="color: #00F6FF;" @click="addLp1=blance_mt">MAX</div>
            </div> -->
            <div class="pledge_tips">
              <div>{{$t('nftInfo.text16')}}</div>
              <div>{{$t('nftInfo.text17')}}</div>
            </div>
          </div>
          <!-- ULM -->
          <div v-if="addPop1==2">
            <div class="pledge_input">
              <div class="flex_lr" @click="show_ulm=true">
                <div>{{addLp1||$t('nftInfo.text18')}}</div>
                <i style="font-size:12px;color:#21C7D5;transform: scale(0.7);" class="ifont icone-sanjiaoxing"/>
              </div>
              <div style="margin-top:5px;color:#949494;">ULME{{$t('nftInfo.text19')}}：{{blance_ulm}}ULME</div>
            </div>
            <div class="pledge_tips">
              <div>{{$t('nftInfo.text20')}}</div>
              <div>{{$t('nftInfo.text21')}}</div>
            </div>
          </div>
        </div>
        <div style="margin-top: 40px;">
          <div :class="(addPop1==1?approve_mt:approve_ulm)>addLp1?'pledge_btn_after':'pledge_btn'" style="width:100%;" @click="addPop1==1?approveMt():approveUlm()">{{$t('nftInfo.text22')}}{{addPop1==1?'MT':'ULME'}}</div>
          <div class="flex_lr" style="margin-top: 10px;">
            <div class="pledge_btn" v-if="addPop1<3" @click="addLpFun1">{{$t('nftInfo.text23')}}</div>
            <div class="pledge_btn" @click="addPop1=0">{{$t('nftInfo.text24')}}</div>
          </div>
        </div>
      </div>
    </div>
<!-- 质押NFT弹窗 -->
    <div class="pledge flex_center" v-if="addPop2" @touchmove.prevent @mousewheel.prevent>
      <div class="mask"></div>
      <div class="pledge_box" :style="$setWidth=='100%'?'max-width: 80%;':'max-width: 500px;'">
        <div class="pledge_img">
          <img src="@/image/jinshan_05.png"  alt="">
        </div>
        <div class="pledge_name">{{$t('nftInfo.text14')}}{{name}}</div>
        <div>
          <!-- MTG -->

           <!-- <div class="block" style="height: 280px;">
              <span class="demonstration" style="color:#fff">选择{{name}}</span>
              <el-carousel height="280px" trigger="click" :autoplay="false" @change="changeNftIds" indicator-position="none" type="card">
                <el-carousel-item v-for="(item,i) in nftArray" :key="item" :label="i" :name="item">
                  <img style="width: 100%;" src="https://goldhill.oss-cn-beijing.aliyuncs.com/mtg_thumb.png"  alt="">
                  <div class="pledge_input">第{{i+1}}个,ID:{{item}}</div>
                </el-carousel-item>
              </el-carousel>
            </div> -->

            <!-- <div class="pledge_input">1</div> -->
            <div class="pledge_tips">
              <div>{{$t('nftInfo.text25')}}</div>
              <div>{{$t('nftInfo.text26')}}</div>
              <div>{{$t('nftInfo.text27')}}</div>
            </div>
        </div>
        <div style="margin-top: 40px;">
        <div class="flex_lr" style="margin-top: 10px;">
            <div class="pledge_btn" style="width:48%;color:#fff" @click="pressureNft">{{$t('nftInfo.text23')}}</div>
            <div class="pledge_btn" @click="addPop2=false">{{$t('nftInfo.text24')}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择ulm -->
    <div class="ulmPop" v-if="show_ulm">
      <div class="mask"></div>
      <div class="ulmPop_box">
        <div class="ulmPop_head flex_lr">
          <div>{{$t('nftInfo.text15')}}</div>
          <div style="color: #00F6FF;" @click="tapULM">{{$t('nftInfo.text23')}}</div>
        </div>
        <div style="max-height:50vh;overflow:auto;">
          <div class="ulmPop_list" @click="chose_ulm=index" :style="chose_ulm==index?'background:#1538BC':''" v-for="(item,index) in ulm_list" :key="index">
            {{item.in}}ULME {{$t('nftInfo.text5')}}{{item.out}}ULME
          </div>
        </div>
      </div>
    </div>
    <!-- 提币 -->
    <div class="bind flex_center" v-if="addPop" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box">
          <div style="color: #000000;font-weight: bold;text-align: center;">{{$t('nftInfo.text28')}}</div>
          <div class="bind_text " style="margin: 10px">
            <div>{{$t('nftInfo.text28')}}≈</div>
            <div>{{fromWei(takeArray[0])+'MT +'+fromWei(takeArray[1])+"ULME"}}</div>
          </div>
          <div class="bind_text " style="margin: 10px">
            <div>{{$t('nftInfo.text29').replace(/\|NUM\|/g,fromWei(takeArray[2]))}}</div>
          </div>
          <div class="flex_lr">
            <button style="margin: 10px;color:#ff0000" @click="addPop=false">{{$t('nftInfo.text24')}}</button>
            <button style="margin: 10px;"  @click="takePending">{{$t('nftInfo.text30')}}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加 -->
    <div class="bind flex_center" v-if="false" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop1=0"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div style="color: #000000;font-weight: bold;">质押</div>
          <div class="bind_text " style="margin: 10px">
            <div>当前余额:{{addPop1==1?(blance_mt+'MT'):(blance_ulm+'ULME')}}</div>
          </div>
          <input type="text" placeholder="请输入要质押的数量" v-model="addLp1">
          <div class="flex_lr" style="width:100%;margin-bottom:10px;">
            <button style="width:100%;" :style="'color:'+((addPop1==1?approve_mt:approve_ulm)>addLp1?'#e0e0e0':'#ff0000')"  @click="addPop1==1?approveMt():approveUlm()">授权{{addPop1==1?'MT':'ULME'}}</button>
          </div>
          <button @click="addLpFun1">提交</button>
        </div>
      </div>
    </div>
    <!-- 增加-end -->
    <!-- MTG存量查询弹窗 -->
    <div class="mtg flex_center" v-if="show_MTG">
      <div class="mask" @click="show_MTG = false"></div>
      <div class="mtg_box y_center">
        <div class="mtg_title">MTG存量查询</div>
        <input class="mtg_input" type="text" v-model="tokenIds" placeholder="请输入MTG ID">
        <div style="width:90%;margin-top: 10px;">
          <div class="mtg_text">已领MT:{{info.reMt}}</div>
          <div class="mtg_text">已领ULME:{{info.reUlm}}</div>
        </div>
        <div class="mtg_btn flex_center" @click="queryNft">确定</div>
      </div>
    </div>
    <!-- MTG存量查询弹窗-end -->

    <!-- 修正速度弹窗 -->
    <div class="bind flex_center" v-if="speed" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" style="background: rgba(0,0,0,0.6);backdrop-filter: blur(3px);"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>您的设置有错,请更正</div>
          </div>
          <button @click="changeTimeClick">更正</button>
        </div>
      </div>
          
      <div style="height: 50px;"></div>
    </div>
    <!-- 修正速度 -->

  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';

export default {
  data () {
    return {
      pressureNftTokenId:undefined,
      show_MTG:false,
      // 添加弹窗
      addPop:false,
      addPop2:false,
      addPop1:0,
      speed:false,
      nftIds:0,
      relNft:[],
      takeUlm:{},
      info:{},
      name:'MTG',
      date_list:[],
      chose_date:0,
      approve_mt:0,
      approve_ulm:0,
      approve_usdt:0,
      addLp:"",
      tokenIds:"",
      addLp1:"",
      nType:"mt",
      nTypeName:"MT",
      pair:undefined,
      addressToken:'',
      isLoading:false,
      current_page:0,
      blance_ulm:0,
      blance_mt:0,
      level:0,
      last_page:0,
      is_next:false,
      loading:0,
      thread:0,
      takeArray:[],
      nftArray:[],
      show_ulm:false,
      chose_ulm:-1,
      ulm_list:[
        {
          in:50000,
          out:200
        },
        {
          in:100000,
          out:500
        },
        {
          in:150000,
          out:1000
        },
      ]
    }
  },
  computed: {
    	userInfo() {
			return this.$store.state.user.userInfo
		},
	},
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  async mounted () {
    await this.$onLaunched;
    this.init()
    this.getApprove()
  },
  beforeDestroy(){
    if(this.thread>0){
      clearInterval(this.thread)
    }
  },
  watch: {
    addPop1:{
      handler (val, oldVal) {
	      if(this.addPop1==0){
          this.addLp1=''
        }
      },
      deep:true
    },
    show_ulm:{
      handler (val, oldVal) {
	      if(!this.show_ulm){
          this.chose_ulm=-1
        }
      },
      deep:true
    }
  },
  methods: {
    queryNft(){
      const that=this
      if(this.tokenIds==''){
        this.$toast('请输入ID')
        return
      }
      walletHelper.getContract('rel').methods._relPools(this.tokenIds).call().then(res=>{
          that.$set(that.info,'reMt',walletHelper.Wei(res.reMt))
          that.$set(that.info,'reUlm',walletHelper.Wei(res.reUlm))

      })
      

    },
    toNFT(){
      window.location.href = 'http://top.qiaodex.cc/#/nft_list?token=0xB80Ed8EB2354D934aF6d4575263654913bC0094A'
    },
    changeNftIds(e){
      console.log(e)
      this.nftIds=e
    },
    changeTimeClick(){
      const that=this

      this.sendWeb3(walletHelper.getContract('rel').methods.updateStatus(walletHelper.getAddress())).then(res=>{
        console.log(res)
        that.$toast('更正成功')
        that.init()
      })
    },
    openNft(){
      const that=this
      if(Number(that.info.count)==0){
        this.$toast({
            title: '您还没有'+that.name
        });
        return
      }
      // that.nftArray=new Array(that.info.count)
      // console.log(that.info.count,that.nftArray)
      // for(let i=0;i<that.info.count;i++){
      //   walletHelper.getNFT().methods.tokenOfOwnerByIndex(walletHelper.getAddress(),i).call().then(res=>{
      //       that.nftArray[i]=res
      //   })
      // }
      that.addPop2=true
      
    },
    // 确定选择ulm
    tapULM(){
      if(this.chose_ulm==-1){
        return
      }
      this.addLp1 = this.ulm_list[this.chose_ulm].in
      this.show_ulm = false
    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    init() {
      const that=this
      walletHelper.getNFT().methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.$set(that.info,'count',res)
      })
      walletHelper.getContract('rel').methods.getPressureNftTokenId(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.pressureNftTokenId=res
          if(that.pressureNftTokenId>0){

              that.thread=setInterval(()=>{
                walletHelper.getContract('rel').methods.getTakeULM(that.pressureNftTokenId).call().then(res=>{
                    // console.log(res)
                    that.takeUlm=res
                })
                walletHelper.getContract('rel').methods.getReleaseNFT(that.pressureNftTokenId).call().then(res=>{
                    // console.log(res)
                    that.relNft=res
                })
              },3*1000)
            
            walletHelper.getContract('rel').methods.getTakeULM(that.pressureNftTokenId).call().then(res=>{
              console.log(res)
              that.takeUlm=res
          })
          walletHelper.getContract('rel').methods.getReleaseNFT(that.pressureNftTokenId).call().then(res=>{
              console.log(res)
              that.relNft=res
          })
          walletHelper.getContract('rel').methods._relPools(that.pressureNftTokenId).call().then(res=>{
            console.log(res)
            that.$set(that.info,'_relPools',res.levelUlm)

            walletHelper.getContract('rel').methods.getSpeed(that.pressureNftTokenId,walletHelper.getAddress()).call().then(res1=>{
              console.log(res1,'speed')
              that.speed=res1!=res.levelUlm
            })
            // if(res.levelUlm==0){
            //     that.$set(that.info,'_relPools',0)
            // }else if(res.levelUlm>678703703703700){
            //   that.$set(that.info,'_relPools',1000)
            // }else if(res.levelUlm>331481481481480){
            //   that.$set(that.info,'_relPools',500)
            // }else{
            //   that.$set(that.info,'_relPools',200)
            // }
            that.$set(that.info,'reMt',walletHelper.Wei(res.reMt))
            that.$set(that.info,'reUlm',walletHelper.Wei(res.reUlm))

        })

          }
          
      })

      walletHelper.getContract('rel').methods.getPressureCount(walletHelper.getContractAddress('mt'),walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.$set(that.info,'relCoinMt',walletHelper.Wei(res))
      })
      walletHelper.getContract('rel').methods.getPressureCount(walletHelper.getContractAddress('ulm'),walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.$set(that.info,'relCoinUlm',walletHelper.Wei(res))
      })
      walletHelper.getContract('daoV1').methods.data(walletHelper.getAddress(),walletHelper.dataIndex._userLevel).call().then(res=>{
          console.log(res)
          that.level=res

      })

    },
    pressureNft(){
      const that=this
      if(!that.info.count||that.info.count==0){
        this.$toast({
            title: '您还没有'+this.name
        });
        return
      }
      if(walletHelper.isNotTranfer()){
        return
      }
      walletHelper.getNFT().methods.tokenOfOwnerByIndex(walletHelper.getAddress(),0).call().then(res=>{
        console.log('nft',res)
        walletHelper.getNFT().methods.isApprovedForAll(walletHelper.getAddress(),walletHelper.getContractAddress('rel')).call().then(e=>{
          if(e){
            
            this.sendWeb3(walletHelper.getContract('rel').methods.activateReleaseULM(String(res))).then(res=>{
                console.log(res)
                that.$toast('质押成功')
                that.addPop2=false
                that.init()
            })
          }else{//批准
            this.sendWeb3(walletHelper.getNFT().methods.setApprovalForAll(walletHelper.getContractAddress('rel'),'true')).then(res=>{
                console.log(res)
                that.$toast('批准成功')
                that.pressureNft()
            })
          }
          
        })
      })
    },
    redeemNFT(){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      this.sendWeb3(walletHelper.getContract('rel').methods.redeemNFT()).then(res=>{
          console.log(res)
          that.$toast('赎回成功')
          that.init()
      })
    },
    renouncePressure(ntype){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }

      let token=ntype==1?walletHelper.getContractAddress('mt'):walletHelper.getContractAddress('ulm')
      this.sendWeb3(walletHelper.getContract('rel').methods.renouncePressure(token)).then(res=>{
          console.log(res)
          that.$toast('赎回成功')
          that.init()
      })
    },
    fromWei(wei){
      return wei?walletHelper.Wei(wei):0
    },
    take(){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      if(that.takeUlm[0]>0){
        this.sendWeb3(walletHelper.getContract('rel').methods.takeULM(that.pressureNftTokenId)).then((result)=>{
          console.log(result)
          that.init()
          that.$toast('提取成功')
          that.addPop=false
        })
      }else{
        this.$toast({title:'无可领取'})
      }
    },
    takeNft(){
      const that=this
      if(that.relNft[0]>0||that.relNft[1]>0){
        if(walletHelper.isNotTranfer()){
          return
        }
        this.sendWeb3(walletHelper.getContract('rel').methods.takeReleaseNFT()).then((result)=>{
          console.log(result)
          that.init()
          that.$toast('提取成功')
          that.addPop=false
        })
      }else{
        this.$toast({title:'无可领取'})
      }
      
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    getApprove(){
      const that=this
      walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('rel')).call().then(res=>{
          console.log(res)
          that.approve_mt=walletHelper.Wei(res)
      })
      walletHelper.getContract('ulm').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('rel')).call().then(res=>{
          console.log(res)
          that.approve_ulm=walletHelper.Wei(res)
      })

      walletHelper.getContract('mt').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.blance_mt=walletHelper.Wei(res)
      })
      walletHelper.getContract('ulm').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.blance_ulm=walletHelper.Wei(res)
      })
    },

    approveMt(){
      const that=this
      this.sendWeb3(walletHelper.getContract('mt').methods.approve(walletHelper.getContractAddress('rel'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        that.getApprove()
        that.$toast('MT授权成功')
      })
    },
    approveUlm(){
      const that=this
      this.sendWeb3(walletHelper.getContract('ulm').methods.approve(walletHelper.getContractAddress('rel'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        that.getApprove()
        that.$toast('ULME授权成功')
      })
    },
    approveUsdt(){
      const that=this
      this.sendWeb3(walletHelper.getUsdt().methods.approve(walletHelper.getContractAddress('rel'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        that.getApprove()
        that.closeLoading('USDT授权成功')
      })
    },
    addLpFun1(){
      const that=this
      if(that.addPop1==1){
          that.addLp1='0.1'
        }
      if(that.addLp1==''||that.addLp1==0){
        that.$toast({
            title: '请输入要质押的数量'
        });
        return 
      }
      if((that.addPop1==1?that.approve_mt:that.approve_ulm)==0){
        that.$toast({
            title: '请先授权再添加'
        });
        return
      }
      if(walletHelper.isNotTranfer()){
        return
      }
        let token=that.addPop1==1?walletHelper.getContractAddress('mt'):walletHelper.getContractAddress('ulm')
        
        this.sendWeb3(walletHelper.getContract('rel').methods.pressure(token,walletHelper.toWei(String(that.addLp1)))).then((result)=>{
          console.log(result)
          that.addPop1=0
          that.addLp1=''
          that.closeLoading('质押成功')
          that.init()
        });


    },
  },
}
</script>
 
<style lang="scss" scoped>
  .mtg{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin: auto;
    .mtg_box{
      position: relative;
      z-index: 3;
      background: #ffffff;
      color: #ffffff;
      background: #0C2379;
      border: 2px solid #21C7D5;
      border-radius: 5px;
      width: 80%;
      .mtg_title{
        color: #21C7D5;
        margin-top: 10px;
        font-size: 1rem;
      }
      .mtg_input{
        width: 90%;
        margin-top: 10px;
        font-size: 0.9rem;
        background: #041453;
        padding: 10px;
        border-bottom: 5px;
        color: #ffffff;
      }
      .mtg_input::-webkit-input-placeholder{color:rgb(214, 214, 214);}
      .mtg_input::-moz-placeholder{color:rgb(214, 214, 214);}
      .mtg_input:-ms-placeholder{color:rgb(214, 214, 214);}
      .mtg_text{
        font-size: 0.9rem;
        margin-top: 3px;
      }
      .mtg_btn{
        margin: 40px 0 10px;
        width: 90%;
        text-align: center;
        background: #00FFEA;
        border-radius: 5px;
        color: #000000;
        font-size: 0.9rem;
        padding: 10px 25px;
        white-space: nowrap;
      }
    }
  }
  .ulmPop{
    position: fixed;
    // top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1001;
    font-family: PingFang SC;
    .ulmPop_box{
      position: relative;
      z-index: 2;
      color: #ffffff;
      background: #0C2379;
      border: 2px solid #21C7D5;
      border-radius: 10px 10px 0 0 ;
      padding: 0 15px;
      .ulmPop_head{
        padding: 15px 0;
      }
      .ulmPop_list{
        background: #041453;
        padding: 15px;
        margin-bottom: 10px;
        font-size: 0.7rem;
        border-radius: 5px;
      }
    }
  }
  .pledge{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .pledge_box{
      position: relative;
      background: #0C2379;
      border: 2px solid #21C7D5;
      padding: 25px 15px 30px;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      width: 80%;
      .pledge_img{
        position: absolute;
        top: -25px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
        background: #0C2379;
        border: 2px solid #21C7D5;
        width: 50px;
        height: 50px;
        padding: 6px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .pledge_name{
        font-size: 1rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #00F6FF;
        line-height: 24px;
        text-align: center;
        margin-bottom: 20px;
      }
      .pledge_tips{
        color: #fff;
        font-size: 0.7rem;
        line-height: 1.5rem;
      }
      .pledge_input{
        width: 100%;
        background: #041453;
        border-radius: 5px;
        padding: 15px 13px;
        margin: 10px 0;
        color: #ffffff;
        font-size: 0.8rem;
        input{
          background: none;
          margin-top: 10px;
          color: #ffffff;
        }
      }
      .pledge_btn{
        text-align: center;
        background: #00FFEA;
        border-radius: 5px;
        color: #000000;
        font-size: 0.9rem;
        padding: 10px 25px;
        white-space: nowrap;
        width: 48%;
      }
      .pledge_btn_after{
        text-align: center;
        background: #f0f0f0;
        border-radius: 5px;
        color: #999;
        font-size: 0.9rem;
        padding: 10px 25px;
        white-space: nowrap;
        width: 48%;
      }
    }
  }
  .zy_title{
    color: #ffffff;
    img{
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
  .nav{
    width: 48%;
    background: #041834;
    color: #737373;
    height: 50px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 1rem;
  }
  .LP_title{
    color: #00FFEA;
    font-size: 1rem;
    padding: 10px 0;
  }
  .LP_text{
    color: #ffffff;
    font-size: 0.85rem;
    padding: 5px 0;
  }
  .LP_num{
    color: #00FFEA;
    margin-left: 10px;
  }
  .LP_box0{
    width: 100%;
    background: #010C1B;
    border-radius:5px;
    padding:10px;
    margin-bottom: 10px;
  }
  .LP_box1{
    width: 100%;
    background: #1332B7;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
    .LP_title{
      color: #ffffff;
    }
  }
  .LP_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 10px 13px;
    margin-bottom: 10px;
  }
  .LP_num1{
    text-align: right;
    font-size: 0.8rem;
    color: #ffffff;
  }
  .LP_num2{
    text-align: right;
    font-size: 0.75rem;
    color: #00FFEA;
  }
  .LP_btn{
    width: 100%;
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btnM{
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 10px;
    margin: 10px 0;
    white-space: nowrap;
  }
  .LP_btnh{
    width: 100%;
    text-align: center;
    background: #990000;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #00FFEA;
    border-radius: 5px;
    color: #000000;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .date_show{
    position: relative;
    z-index: 4;
    height: 20px;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 0 8px;
    i{
      font-size: 0.75rem;
      color: #1870EA;
      transform: scale(0.7);
      margin-left: 10px;
    }
  }
  .date_list{
    position: absolute;
    z-index: 3;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 20px 8px 0;
    .date_item{
      padding: 3px 0;
    }
  }
  .address{
      width: 0;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  .bind{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .bind_bg{
      background: #21C7D5;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .bind_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .bind_text{
          color: #CE0F0F;
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 5px;
        }
        .bind_text1{
          color: rgb(14, 245, 137);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        .bind_text2{
          color: rgb(233, 99, 22);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        input{
          font-size: 16px;
          // width: 0;
          // flex: 1;
          width: 100%;
          padding: 15px 15px;
          background: #ffffff;
          border-radius: 3px;
          // min-height: 100px;
          color: #00DEFF;
          line-height: 18px;
          margin: 15px 0 10px;
        }
        // chrome
        input::-webkit-input-placeholder { /* WebKit browsers */
          color: #999999;
          font-size:15px;
        }
        // firefox
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #999999;
          font-size:15px;
        }
        button{
          background: #ffffff;
          color: #177C85;
          font-size: 14px;
          border: none;
          // margin-left: 10px;
          border-radius: 3px;
          height: 40px;
          // width: 80px;
          width: 100%;
        }
      }
    }
  }
</style>